<script setup>
import { useFetchFaqs } from "./business_logic";
import '@/assets/css/quill.snow.css'

const { faqs } = useFetchFaqs();
</script>

<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="auto" class="text-h5 primary--text font-weight-bold">
        Frequently Asked Questions
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-expansion-panels
          class="faq-expansion-panels"
          v-if="!faqs.isLoading.value"
          accordion
        >
          <v-expansion-panel
            v-for="faq in faqs.data.value"
            :key="faq.id"
            :style="{ backgroundColor: 'transparent' }"
          >
            <v-expansion-panel-header>
              <div v-html="faq.ui_content" class="text-h6 ql-editor"></div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div
                v-for="(question, index) in faq.questions"
                :key="question.id"
                class="ml-6"
              >
                <div
                  :class="{
                    'text-decoration-underline font-weight-bold': true,
                    'mt-4': index !== 0,
                  }"
                >
                  {{ question.question }}
                </div>
                <div
                  class="mt-2 ml-md-8 text-body-2 question-response ql-editor"
                  v-html="question.response"
                ></div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <div v-else>
          <v-row v-for="i in 3" :key="i" justify="center">
            <v-col cols="8">
              <v-card flat class="pa-4 reduced-opacity-background">
                <v-skeleton-loader
                  type="button"
                  class="skeleton-fill-width"
                  width="100%"
                >
                </v-skeleton-loader>
                <v-skeleton-loader type="paragraph" class="mt-4">
                </v-skeleton-loader>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>
// :deep(.faq-list) {
//   & .v-list-group__header {
//     background-color: #3f51b550 !important;
//   }
// }

:deep(.faq-expansion-panels) {
  & .v-expansion-panel::before {
    box-shadow: none;
  }

  & p {
    margin-bottom: 2px;
  }

  .ql-editor {
    padding: 0
  }
}
</style>
