import apiCall from "@/utils/api.js";
import { useQuery } from "@tanstack/vue-query";

export function useFetchFaqs() {
  async function fetchFaqs({}) {
    try {
      const response = await apiCall({
        url: "/api/client/faq",
        method: "GET",
      });
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  const faqs = useQuery({
    queryKey: ["faqs"],
    queryFn: fetchFaqs,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  return { faqs };
}
